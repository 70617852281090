html {
  scroll-behavior: smooth;
  background-color: #000;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

h1 {
  margin: 0;
}

p {
  margin: 0;
}

img {
  display: block;
  width: 100%;
}

a {
  text-decoration: none;
}
